@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Urbanist', sans-serif;
  scroll-behavior: smooth;
}

#section9Scroll::-webkit-scrollbar,#section17Scroll::-webkit-scrollbar {
  display: none;
}

input:focus{
  /* border:none; */
  outline:none
}

::-webkit-scrollbar {
  width: 10px; /* adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* change the background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* change the color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* change the color of the scrollbar thumb on hover */
}