.post-project-button {
    background-color: black;
    color: white;
    margin-left: 60px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
}

.browse-freelancer-button {
    background-color: black;
    color: white;
    margin-left: 80px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
}

.about-postJobs {
    display: flex;
    flex-direction: column;
    gap:50px;width: 88%;
    margin: 0 auto;
    margin-top: 60px;
}

.first-box {
    border: none;
    box-shadow: 0px 0px 4px 0px #00000040;
    padding: 20px 20px 30px 35px;
    border-radius: 16px;
    
}

.first-box-one {
    width: 92%;
    padding: 15px 20px 30px;
    font-weight: 400;
    font-size: 18px;
    margin-top: 7px;
    border-radius: 16px;
    box-shadow: 0px 0px 4px 0px #00000040;
    border: none;
    resize: none;
    overflow: hidden;
    min-height: 100px; 
    outline: none;
}

.first-box-two {
    width: 92%;
    padding: 15px 20px;
    font-weight: 400;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 16px;
    box-shadow: 0px 0px 4px 0px #00000040;
    border: none;
    resize: none;
    min-height: 50px; 
    outline: none;
}

.first-box-three-editable{
    width: 92%;
    padding: 15px 20px;
    font-weight: 400;
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 20px;
    border-radius: 16px;
    box-shadow: 0px 0px 4px 0px #00000040;
    border: none;
    resize: none;
    min-height: 50px; 
    outline: none;

}
.first-box-three {
    width: 92%;
    padding: 0px 20px;
    font-weight: 700;
    font-size: 20px;
    margin-top: 50px;
    /* border-radius: 16px; */
    /* box-shadow: 0px 0px 4px 0px #00000040; */
    border: none;
    resize: none;
    /* min-height: 50px;  */
    outline: none;
}

/* .review-box-employer {
    margin-top: -180px;
    width: 82%;
    height: 500px;
    border: 1px solid #9c9b9b;
    margin-left: 100px;
    margin-bottom: 70px;
    border-radius: 20px;
} */

.inside-posted-jobs {
    width:92%;
    display: flex;
    flex-direction: column;
    gap:25px;
    margin-top: 10px;
}
.postjob-profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    border-radius: 16px;
    box-shadow: 0px 0px 4px 0px #00000040;
    width: 92%;
    padding: 60px 10px;
    cursor: pointer;
}
.container {
    position: relative;
}

.dropdown {
    height: 300px;
    width: 300px;
    margin-top: 20px;
    border: 1px solid #9c9b9b;
    position: absolute;
    top: 60%;
    /* Position the dropdown below the button */
    margin-left: -300px;
    /* Adjust the width as needed */
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 2;
    border-radius: 20px;
}

@media only screen and (max-width:880px){
    .about-postJobs{
     width: 92%;
     margin-top: 140px !important;
     gap:30px !important
    }
}
@media only screen and (max-width:600px){
    .about-postJobs{
        margin-top: 120px !important;
       }
    .first-box-one,.first-box-two{
        width: 98% !important;
        margin-top: 10px !important;
     }
     .first-box{
        padding: 20px 20px 30px 20px;
     }
     .profileseclink{
        margin-left: auto;
        margin-right: 0 !important;
     }
     .inside-posted-jobs{
        width: 98% !important;
     }
}