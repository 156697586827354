@media only screen and (max-width:1300px){
    .walletsetuptpage{
        padding: 0 50px !important;
        margin-top: 100px !important;
    }
    .walletsetuppage2{
        padding: 0 60px !important;
        margin-top: 80px !important;
        width: 70% !important;
    }
}
@media only screen and (max-width:1260px){
    .walletsetuptsectionimg img{
        width: 100% !important;
    }
}
@media only screen and (max-width:1200px){
    .walletsetuptpage{
        padding: 0 20px !important;
        margin-top: 100px !important;
    }
}
@media only screen and (max-width:1000px){
    .walletsetuptpage{
          flex-direction: column !important;
    }
    .walletsetuptsection{
        display: grid !important;
        grid-template-columns: repeat(2,1fr);
    }
    .walletsetuptpage{
        padding: 0 50px !important;
        gap:50px !important;
        margin-top: 80px !important;
    }
    .walletsetuptsectionimg{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px !important;
    }
}
@media only screen and (max-width:900px){
    .walletsetuptpage{
        margin-top: 110px !important;
    }
}
@media only screen and (max-width:800px){
    .walletsetuptsection{
        grid-template-columns: repeat(1,1fr);
        gap:30px !important;    }
        .walletsetuptpage{
            padding: 0 40px !important;
            gap:50px !important;
        }
        .walletcont5{
            font-size: 18px !important;
            text-align: center !important;
        }
        .modalcon2{
            padding: 15px 0% !important;
        }
        .walletsetuppage2{
            width: 84% !important;
            margin-top: 60px !important;
        }
}
@media only screen and (max-width:600px){
    .walletsetuptpage{
            padding: 0 30px !important;
            gap:50px !important;
            margin-top: 100px !important;
    }
    .walletsetuptsectionimg img{
       width:100% !important
    }
    .modalcon1{
        width: 90% !important;
    }
    .walletsetuppage2{
        width: 100% !important;
        margin-top: 60px !important;
        padding: 0 40px !important;
    }
    .walletcon6{
        flex-wrap: wrap !important;
        gap:10px !important;
        margin-top: 20px !important;
    }
    .cardinput input{
        width: 70px !important;
        padding: 20px 0px !important;
    }
    .cardinput {
        margin-top: 0 !important;
        gap:10px !important
    }
}
@media only screen and (max-width:500px){
    .walletsetuptpage{
            padding: 0 20px !important;
            gap:25px !important;
            margin-bottom:30px !important;
    }
    .walletsetuppage2{
        margin-top: 40px !important;
        padding: 0 20px !important;
        margin-bottom: 20px !important;
    }
    .walletsetuptsectionimg img{
       width:100% !important
    }
    .walletcont1{
        font-size: 16px !important;
        margin-left:0px !important;
    }
    .walletcont2{
        font-size: 18px !important;
    }
    .walletcont3{
        font-size: 21px !important;
    }
    .walletbut1{
        font-size: 14px !important;
    }
    .walletcon2{
        padding: 20px !important;
        box-shadow: 0px 0px 2.641054391860962px 0.33013179898262024px #00000040;
    }
    .walletcon1{
        gap:12px !important;
        padding: 20px !important;
    }
    .walletsetuptsection{
        padding: 0 !important;
    }
    .walletcont4{
        font-size: 18px !important;
    }
    .walletcont5{
        font-size: 14px !important;
    }
    .modaltxt1{
        font-size: 18px !important;
    }
    .modaltxt2{
        font-size: 14px !important;
    }
    .modalcon2{
        display: grid !important;
        grid-template-columns: repeat(3,1fr) !important;
        gap: 20px !important
    }
    .modalcon2 div{
        width: auto !important;
    }
    .modalcon2 div p{
        font-size: 14px !important;
        text-align: center;
    }
    .modalcon2 div svg{
        font-size: 24px !important;
    }
    .modalbut{
        font-size: 14px !important;
        width: 95px !important;
        padding: 10px 20px !important;
    }
    .modalbuts{
        gap:10px !important;
        justify-content: left !important;
        flex-direction: row-reverse !important;
    }
    .walletcont6{
        font-size: 20px !important;
    }
    .walletcont7{
        font-size: 15px !important;
        margin-top: 5px !important;
    }
    .walletcon3{
        width: 92% !important;
        margin-top: 30px !important;
        
    }
    .walletcon4{
        margin-top: 0 !important;
        gap: 10px !important;
    }
    .walletcon4 p{
       font-size: 16px !important;
    }
    .walletcon4 input{
        font-size: 16px !important;
        padding: 15px 20px !important;
        outline: none;
     }
     .walletbut2{
        width: 200px !important;
        font-size: 14px !important;
        /* margin-top: 15px !important; */
     }
     .walletcon5{
        margin-top: 30px !important;
        gap:7px !important
     }
     .cardinput input{
        width: 65px !important;
        padding: 15px 10px !important;
        text-align: center;
       
    }
}