.startpage{
    margin: 60px 0;
}
.header-logo {
    color: #b27ee3;
    margin-left: 5%;
    font-size: 30px;
}
.headerStyle{
    background: black;
    display: flex;
    align-items: center;
    height: 72px !important;
}

.one{
    margin-top: 60px;
}
.one h2{
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    letter-spacing: -1px;
}

.join-type{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-end; */
    width: 340px;
    /* height: 220px; */
    border-radius: 12px;
    border: 2px solid ;
    background-color: white;
    cursor: pointer;
    padding: 35px 25px 35px;
    transition: background-color 0.3s ease;
}
.join-typeimg{
    /* width: 300px; */
    height:200px;
}
.join-type p{
    margin: 3px 0;
    text-align: center;
}
.join-type1{
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -1px;
}
.join-type2{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -1px;
    color: #656565;

}
.jointype-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    gap:20px
}

.two {
    text-align: center;
    font-size: 20px;
    margin-top: 60px;
}
.two h5{
    margin: 15px 0;
    color: #656565;
}
.two button {
    border-radius: 10px;
    border: none;
    background-color: #b27ee3;
    color: white;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    padding: 10px 25px;
}

@media only screen and (max-width:900px){
    .jointype-container {
        flex-direction: column;
        padding: 0 5%;
    }
    .join-type{
        width: 90%;
    }
    .one h2{
        padding: 0 5%;
    }
}
@media only screen and (max-width:600px){
    .one h2{
        font-size: 30px;
        padding: 0 8%;
    }
    .join-typeimg{
        height:150px;
    }
    .join-type p{
        margin: 3px 0;
    }
    .join-type1{
        font-size: 24px;
    }
    .join-type2{
        font-size: 15px;
    }
    .jointype-container {
        gap:30px;
        padding: 0;
    }
    .join-type{
        width: 75%;
        margin: auto;
    }
}