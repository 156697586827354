@media only screen and (max-width:1200px){
    .managejobs{
     flex-direction: column !important;
     gap:60px !important;
     margin-top: 60px !important;
    }
    .managejobs-opts{
        margin-top: 0 !important;
        display: grid !important;
        grid-template-columns: repeat(4,1fr) !important;
        margin: auto !important;
        width:100% ;
    }
    .managejobs-opts-client{
        grid-template-columns: repeat(3,1fr) !important;
    }
    .managejobs-display{
        padding: 0 !important;
        width: 100% !important;
    }
    .ongoingjobslider{
        width: 250px !important;
    }
 }
 @media only screen and (max-width:850px){
    .managejobs{
        margin-top: 40px !important;
        gap:40px !important;
       }
    .managejobs-page{
        padding:50px !important;
    }
    .ongoingjobslider{
        width: 200px !important;
    }
 }
 @media only screen and (max-width:770px){
    .ongoingjobslider{
        width: 270px !important;
    }
    .managejobs-opts{
        grid-template-columns: repeat(2,1fr) !important;
    }
    .managejobs-opts-client{
        grid-template-columns: repeat(1,1fr) !important;
    }
 }
 @media only screen and (max-width:600px){
    .managejobs-page{
        padding:30px !important;
    }
    .managejobs-opts-client{
        margin: 0 !important;
        width: auto !important;
    }
    .ongoingjobslider{
        width: 220px !important;
    }
 }
 @media only screen and (max-width:500px){
    .job-dots{
        font-size: 18px !important;
    }
    .job-con1{
        font-size: 17px !important;
    }
    .job-con2{
        font-size: 14px !important;
    }
    .job-con3{
        font-size: 12px !important;
    }
    .ongoingjobslider{
        width: 150px !important;
        height: 5px !important;
    }
    .MuiSlider-thumb{width: 18px !important;height: 18px !important}
    .job-description{
        margin-right: 0px !important;
        gap:5px;
    }
    .managejobs{
        margin-top: 20px !important;
        gap:30px !important
       }
       .jobs-category{
        font-size: 21px !important;
       }
       .job-action{
        font-size: 15px !important;
        width: fit-content !important;
        padding: 8px 12px !important
       }
       .job-description{
        gap:10px !important
       }
       .job{
        padding: 25px 20px !important;
       }
       .manageprofilelink{
        font-size: 18px !important;
       }
       .managejobs-opts button{
        font-size: 15px !important;
    }
    .managejobs-title{
        font-size: 24px !important;
    }
    .job-opts{
        gap:10px !important
    }
 }
 @media only screen and (max-width:400px){
    .managejobs-opts button{
        font-size: 13px !important;
    }
    .job-container{
        padding-left: 12px !important;
    }
 }